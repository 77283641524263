import {createApp, Events} from 'vue'
import axios from "axios";
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'


import App from './App.vue'
import './index.css'
import router from './router';

import Default from "./layouts/Default.vue";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

import { Vue3Mq } from "vue3-mq";

import PerfectScrollbar from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'
import Emitter from "./utils/emitter";

import VueSocialSharing from 'vue-social-sharing'
import Form from "./components/forms/Form.vue";
import VueNumber from 'vue-number-animation';

const app = createApp(App)

const emitter = Emitter;
app.provide("emitter", emitter);

axios.defaults.withCredentials = true;
axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
axios.defaults.baseURL = import.meta.env.VITE_BASE_API_URL as string;

if (import.meta.env.VITE_ENABLE_SENTRY === "true") {
    Sentry.init({
        app,
        dsn: "https://5c4d5bddda034e0f8bb4d681bbac2318@o1410877.ingest.sentry.io/6748454",
        environment: <string>import.meta.env.VITE_APP_ENV,
        integrations: [
            new BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracingOrigins: ["localhost", "tcgcompare.com", "dev.tcgcompare.com", /^\//],
            }),
        ],
        tracesSampleRate: import.meta.env.VITE_SENTRY_TRACE_RATE,
    });
}

app.component('default-layout', Default);

app.component('Form', Form);

app.use(PerfectScrollbar)

app.use(VueNumber);

app.use(router)
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate)

app.use(pinia);


app.use(Vue3Mq, {
    preset: "tailwind"
});

app.config.performance = true;

app.use(VueSocialSharing);

app.mount('#app')
