import {defineStore} from "pinia";
import Site from "../../types/Site";
import SiteApiClient from "../../api/SiteApiClient";

const useSiteStore = defineStore({
    id: "sites",
    state: () => ({
        sites: [] as Site[]
    }),
    actions: {
        async listSites(countryCode: string) {
            const response = await SiteApiClient.listSites(countryCode);
            if(response.success){
                this.sites = response.data ?? [];
            }
        },
        getSiteNameBySlug(slug: string){
            return this.sites.find(x => x.slug === slug)?.name;
        },
        getSiteBySlug(slug: string){
            return this.sites.find(x => x.slug === slug);
        }
    }
});

export default useSiteStore;