<template>
    <Modal :visible="showConfirmationModal" size="small" title="Confirm Unsubscription">
        <template #footer-buttons>
            <div class="flex flex-row gap-2">
                <button
                    @click="unsubscribeAll"
                    class="flex items-center justify-center px-4 py-3 border border-transparent font-medium rounded-md shadow-sm bg-red-700 text-white hover:bg-red-500 sm:px-4">
                    Unsubscribe
                </button>
                <button
                    @click="showConfirmationModal = false"
                    class="flex items-center justify-center px-4 py-3 border border-transparent font-medium rounded-md shadow-sm text-white hover:bg-gray-600 sm:px-4">
                    Cancel
                </button>
            </div>
        </template>
        <div class="flex flex-col">
            <span class="font-bold">Are you sure you wish to unsubscribe from all alerts?</span>
            <span class="text-sm mt-2">Note: You can re-subscribe at any time by pressing "Enable" on any alert listed, or by entering your email address on a product page.</span>
        </div>
    </Modal>
    <LoadingSpinner v-show="isLoading"></LoadingSpinner>
    <div class="mb-5" v-if="!isLoading">
        <div class="mt-4 mb-5 md:flex md:items-center md:justify-between px-2">
            <div class="flex-1 w-full">
                <div class="flex flex-col md:flex-row justify-between">
                    <h1 class="text-2xl font-bold leading-7 text-gray-900 dark:text-gray-200 sm:text-3xl sm:truncate">
                        Manage Alert Subscriptions</h1>
                    <div class="mt-2 md:mt-0">
                        <button
                            v-if="subscriptions && !isUnsubscribing"
                            @click="showConfirmationModal = true"
                            class="flex items-center justify-center px-4 py-3 border border-transparent text-xs font-medium rounded-md shadow-sm bg-red-700 text-white hover:bg-red-500 sm:px-4">
                            <XCircleIcon class="h-5 w-5 mr-1" aria-hidden="true"/>
                            Unsubscribe from all alerts.
                        </button>
                        <div
                            v-if="isUnsubscribing"
                            class="inline-flex flex-1 items-center justify-center rounded-b-lg border border-transparent py-3 text-xs">
                            <LoadingSpinnerSmall class="h-4 w-4"/>
                            <span class="text-sm dark:text-gray-200 ml-1">Unsubscribing...</span>
                        </div>
                    </div>
                </div>
                <p class="text-gray-500 dark:text-gray-200 text-sm mt-2">Below you can enable or disable the alerts you
                    have subscribed to.</p>
                <p class="text-center sm:text-right text-gray-700 dark:text-gray-400 text-xs mt-4 ml-2"><strong>Please
                    note:</strong> the maximum number of subscriptions allowed is currently 10.</p>
            </div>
        </div>
        <div v-if="subscriptions">
            <div class="flex flex-row gap-x-2 items-center justify-end mr-4 mb-4 invisible md:visible">
                <span class="dark:text-gray-200 text-xs">Layout: </span>
                <ViewGridIcon
                    class="h-8 dark:text-gray-200 cursor-pointer"
                    @click="setSubscriptionLayout('grid')"
                    :class="{'text-indigo-500 dark:text-indigo-500': layout === 'grid'}"
                />
                <ViewListIcon
                    class="h-8 dark:text-gray-200 cursor-pointer"
                    @click="setSubscriptionLayout('list')"
                    :class="{'text-indigo-500 dark:text-indigo-500': layout === 'list'}"
                />
            </div>
            <ul v-if="subscriptions && layout === 'grid'" role="list"
                class="px-2 mb-4 sm:px-4 lg:ml-2 grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
                <li v-for="subscription in subscriptions" :key="subscription.id"
                    class="relative col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-gray-200 dark:bg-gray-700 text-center shadow">
                    <SubscriptionCard
                        :subscription="subscription"
                        :is-updating="isUnsubscribing"
                        @on-toggle-subscription="toggleSubscription"
                    />
                </li>
            </ul>
            <ul v-else-if="subscriptions && layout === 'list'" class="px-2 mb-4 sm:px-4 lg:ml-2 flex flex-col gap-y-2">
                <li v-for="subscription in subscriptions" :key="subscription.id"
                    class="py-2 relative flex flex-col rounded-lg bg-gray-200 dark:bg-gray-700 text-center shadow grid grid-cols-3 gap-2 lg:grid-cols-12">
                    <SubscriptionListItem
                        :subscription="subscription"
                        :is-updating="isUnsubscribing"
                        @on-toggle-subscription="toggleSubscription"
                    />
                </li>
            </ul>
            <Pagination
                v-if="subscriptionsRaw"
                :paging="subscriptionsRaw"
                @linkClicked="getNewPaginatedData"
            />
        </div>
        <div v-else class="h-screen">
            <p class="flex flex-row gap-2 text-gray-500 dark:text-gray-200 text-xl mt-4 px-4"><XCircleIcon class="h-8 w-8 text-red-500"/> No subscriptions found.</p>
        </div>
    </div>
</template>
<script lang="ts">
import useSubscriptionStore from "../useSubscriptionStore";
import {computed, onBeforeMount, ref} from "vue";
import SubscriptionCard from "../components/SubscriptionCard.vue";
import {XCircleIcon, ViewListIcon, ViewGridIcon} from "@heroicons/vue/solid";
import EmailSubscriptionToggle from "../../../types/EmailSubscriptionToggle";
import {useRoute} from "vue-router";
import useTitleManager from "../../../utils/useTitleManager";
import Pagination from "../../../components/Pagination.vue";
import LoadingSpinner from "../../../components/LoadingSpinner.vue";
import emitter from "../../../utils/emitter";
import NotificationType from "../../../types/NotificationType";
import LoadingSpinnerSmall from "../../../components/LoadingSpinnerSmall.vue";
import Modal from "../../../components/Modal.vue";
import SubscriptionListItem from "../components/SubscriptionListItem.vue";
import useLocalStorageStore from "../../../utils/useLocalStorageStore";

export default {
    components: {
        SubscriptionListItem,
        Modal,
        LoadingSpinnerSmall,
        LoadingSpinner,
        Pagination,
        SubscriptionCard,
        XCircleIcon,
        ViewGridIcon,
        ViewListIcon
    },
    setup() {
        const route = useRoute();
        const { setTitlePrefix } = useTitleManager();
        const subscriptionsStore = useSubscriptionStore();
        const localStorageStore = useLocalStorageStore();

        const isLoading = ref(false);
        const isUnsubscribing = ref(false);
        const showConfirmationModal = ref(false);
        const subscriptions = ref();
        const subscriptionsRaw = computed(() => subscriptionsStore.subscriptions);
        const selectedPage = ref(route.query.page ?? undefined);
        const token = ref(route.params.token ?? undefined);

        const layout = computed(() => localStorageStore.subscriptionLayout);

        onBeforeMount(async () => {
            await setup();
            setTitlePrefix("Manage Alert Subscriptions");
        })

        const setup = async () => {
            isLoading.value = true;
            try{
                await subscriptionsStore.listSubscriptions(token.value);
                await sortSubscriptions();
            }
            catch (e){
                emitter.emit('show-notification', {
                    type: NotificationType.ERROR,
                    title: "Unable to list alerts",
                    body: e.errors.message ?? "There was an error completing your request, please try again later"
                });
            }

            isLoading.value = false;
        }

        const sortSubscriptions = async () => {

            subscriptions.value = subscriptionsRaw.value.data.sort(function (a, b) {
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                return new Date(b.created_at) - new Date(a.created_at);
            });
        }

        const toggleSubscription = async (toggle: EmailSubscriptionToggle) => {
            try {
                await subscriptionsStore.toggleSubscription(toggle.subscription, toggle.active);
            } catch (e) {
                emitter.emit('show-notification', {
                    type: NotificationType.ERROR,
                    title: "Unable to update alert",
                    body: e.errors.message ?? "There was an error completing your request, please try again later"
                });
            }
            toggle.subscription.isLoading = false;
            await sortSubscriptions();
        }

        const unsubscribeAll = async () => {
            showConfirmationModal.value = false;
            isUnsubscribing.value = true;
            try {
                await subscriptionsStore.unsubscribeAll(token.value);
            } catch (e) {
                emitter.emit('show-notification', {
                    type: NotificationType.ERROR,
                    title: "Unable to unsubscribe",
                    body: e.errors.message ?? "There was an error completing your request, please try again later"
                });
            }
            isUnsubscribing.value = false;
        }

        const urlParams = (url) => {
            return new Proxy(new URLSearchParams(new URL(url).search), {
                get: (searchParams, prop) => searchParams.get(prop)
            })
        };

        async function getNewPaginatedData(link) {
            // await productStore.clearProducts();
            const params = urlParams(link.url);
            selectedPage.value = params.page !== "1" ? params.page : undefined;
            // await setup();
        }

        const setSubscriptionLayout = (layout: string) => {
            localStorageStore.setSubscriptionLayout(layout);
        }

        return {
            layout,
            isLoading,
            isUnsubscribing,
            showConfirmationModal,
            subscriptions,
            subscriptionsRaw,
            toggleSubscription,
            unsubscribeAll,
            selectedPage,
            getNewPaginatedData,
            setSubscriptionLayout
        }
    }
}
</script>