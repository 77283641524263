import ProductPrice from "../../../types/ProductPrice";
import {DateTime} from "luxon";
import _ from "lodash";

function apify(productPrice: ProductPrice): ProductPrice {
    return { ...productPrice, price: productPrice.price * 100 };
}

function deApify(productPrice: ProductPrice): ProductPrice {
    return { 
        ...productPrice,
        price: productPrice.price > 0 ? productPrice.price / 100 : 0,
        created_at: DateTime.fromISO(productPrice.created_at)
    }
}

function groupByCountry(prices: ProductPrice[]) {
    return _(prices)
        .groupBy('site.country')
        .map(function(items, country) {
            return {
                country: country,
                prices: items
            };
        }).value();
}

export default {
    apify,
    deApify,
    groupByCountry
}
