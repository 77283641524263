import Country from "../types/Country";
import useMiscInformationStore from "./useMiscInformationStore";

function convertToPence(pounds: number): number {
    return pounds * 100;
}

function convertToPounds(pence: number): number {
    return Number((pence / 100).toFixed(2));
}

function calculateVat(hasVat: boolean, costInPounds: number): number {
    if(hasVat) {
        return convertToPounds(convertToPence(costInPounds) * 0.2);
    }

    return 0;
}

function getDisplayValue(country_code: string, pounds: number, positivePrefix: string = ""): string {

    const miscInformationStore = useMiscInformationStore();
    const countries = miscInformationStore.countries;
    const symbol = countries.find((c) => c.code === country_code)?.currency_symbol ?? null;
    const prefix = pounds < 0 ? "-" : positivePrefix;

    return `${prefix}${symbol}${Math.abs(pounds).toFixed(2)}`;
}

export default {
    convertToPence,
    convertToPounds,
    calculateVat,
    getDisplayValue
};
