<template>

    <div class="pt-2 px-2">
        <nav class="sm:hidden" aria-label="Back">
            <a @click="goBack" class="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700">
                <ChevronLeftIcon class="flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-400" aria-hidden="true"/>
                Back
            </a>
        </nav>
        <nav class="hidden sm:flex" aria-label="Breadcrumb">
            <ol role="list" class="flex items-center space-x-4">
                <li v-if="includeHome">
                    <div class="flex">
                        <router-link to="/" class="text-sm font-medium text-gray-500 dark:text-gray-200 hover:text-gray-700">Home
                        </router-link>
                    </div>
                </li>
                <li v-if="gameSlug">
                    <div class="flex items-center">
                        <ChevronRightIcon class="flex-shrink-0 h-5 w-5 text-gray-400 dark:text-gray-200" aria-hidden="true"/>
                        <router-link :to="`/${gameSlug}`"
                                     class="ml-4 text-sm font-medium text-gray-500 dark:text-gray-200 hover:text-gray-700">{{ gameName }}
                        </router-link>
                    </div>
                </li>
                <li v-for="item in items" :key="item.path">
                    <div class="flex items-center">
                        <ChevronRightIcon class="flex-shrink-0 h-5 w-5 text-gray-400 dark:text-gray-200" aria-hidden="true"/>
                        <router-link :to="`/${gameSlug}/${item.path}`"
                                     class="ml-4 text-sm font-medium text-gray-500 dark:text-gray-200 hover:text-gray-700">{{ item.label }}
                        </router-link>
                    </div>
                </li>
            </ol>
        </nav>
    </div>
</template>
<script lang="ts">
import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/vue/solid";
import {onMounted} from "vue";
import {useRouter} from "vue-router";

export default {
    props: {
        includeHome: {
            type: Boolean,
            required: false,
            default: true
        },
        gameName:{
            type: String,
            required:false
        },
        gameSlug:{
            type: String,
            required:false
        },
        items: {
            type: Array,
            required: false,
            default: []
        }
    },
    components: {
        ChevronLeftIcon,
        ChevronRightIcon
    },
    setup(){
        const router = useRouter();
        const goBack = async () => { await router.back() }

        return {
            goBack
        }
    }
}
</script>
<style>

</style>