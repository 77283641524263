<template>
  <div class="flex flex-1 flex-col py-6 px-3">

    <span class="absolute top-1 right-0 text-xs flex flex-row items-center py-1 px-2 rounded-full last:mr-0 mr-1"
          :class="{'text-white bg-green-700': subscription.active, 'text-white bg-red-700': !subscription.active}">
        <CheckCircleIcon v-if="subscription.active" class="w-4" />
        <XCircleIcon v-if="!subscription.active" class="w-4" />
        <span class="ml-1 text-xs">{{ subscription.active ? 'Active' : 'Disabled' }}</span>
    </span>
    <span class="absolute top-0 left-0 text-xs font-semibold inline-block px-2 rounded-full -ml-1">
      <CountryIcon size="large" :rounded="true" :code="subscription.country.code" />
    </span>

    <div class="flex justify-center">
      <ShoppingCartIcon v-if="subscription.type.slug == 'deals'" class="h-24 text-indigo-700 dark:text-gray-200" />
      <SparklesIcon v-if="subscription.type.slug == 'new-products'" class="h-24 text-indigo-700 dark:text-gray-200" />
      <PresentationChartLineIcon v-if="subscription.type.slug == 'lowest-price'" class="h-24 text-indigo-700 dark:text-gray-200 -scale-x-100" />
      <TruckIcon v-if="subscription.type.slug == 'back-in-stock'" class="h-24 text-indigo-700 dark:text-gray-200" />
    </div>
    <h3 class="mt-1 text-sm font-medium text-gray-900 dark:text-gray-300">{{ subscription.type.name }}</h3>

    <div class="mt-0 md:mt-4 flex flex-col grow">
      <span v-for="(description, i) in alertDescriptions">
        <p v-if="i === subscription.type.slug" class="text-gray-500 dark:text-gray-400 text-xs m-auto">{{description}}</p>
      </span>
    </div>
    <div v-if="subscription.product">
      <dl class="mt-4 flex flex-grow flex-col justify-between">
        <dt class="sr-only">Product Name</dt>
        <router-link v-if="subscription.product" :to="`/${subscription.product.category.game.slug}/${subscription.product.category.slug}/${subscription.product.slug}`" :title="subscription.product.name">
          <dd class="mt-0 md:mt-2 text-xs text-gray-500 dark:text-gray-200 flex flex-col md:flex-row">
            <img class="mx-auto h-16 w-16 flex-shrink-0 rounded-full" :src="subscription.product?.image_path" alt="" />
            <span class="mt-2 md:mt-0 self-center md:ml-1">{{ subscription.product?.name }}</span>
          </dd>
        </router-link>
        <router-link v-if="subscription.product" :to="`/${subscription.product.category.game.slug}/${subscription.product.category.slug}`" :title="subscription.product.category.name">
        <dt class="sr-only">Category</dt>
          <dd class="mt-2 md:mt-0">
            <span class="rounded-full bg-indigo-700 px-2 py-1 text-xs font-medium text-gray-100">{{ subscription.product?.category.name }}</span>
          </dd>
        </router-link>
      </dl>
    </div>
    <div v-else class="mt-0 md:mt-4 flex flex-grow flex-col justify-between">
      <p class="text-gray-500 text-xs">This alert tracks all products for the given country.</p>
    </div>
  </div>
  <div class="-mt-px flex divide-x divide-gray-200">
    <div class="flex w-0 flex-1">
      <button
          v-if="!subscription.isLoading && !isUpdating"
          class="relative inline-flex w-0 flex-1 items-center justify-center rounded-b-lg border border-transparent py-4 text-sm font-medium text-gray-700 dark:text-gray-200 hover:text-gray-500 dark:hover:text-gray-500"

          :title="`${subscription.active ? 'Disable' : 'Enable' } this alert.`"
          @click="toggleSubscription(!subscription.active)"
      >
        <CheckCircleIcon v-if="!subscription.active" class="h-5 w-5 text-green-500" aria-hidden="true" />
        <XCircleIcon v-if="subscription.active" class="h-5 w-5 text-red-500" aria-hidden="true" />
        <span class="ml-1">{{ subscription.active ? 'Disable' : 'Enable' }}</span>
      </button>
      <div
          v-if="subscription.isLoading || isUpdating"
          class="relative inline-flex w-0 flex-1 items-center justify-center rounded-b-lg border border-transparent py-4 text-xs">
        <LoadingSpinnerSmall class="h-4 w-4" />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import {defineComponent, PropType, ref} from "vue";
import {CheckCircleIcon, XCircleIcon, BellIcon, PresentationChartLineIcon, SparklesIcon, ShoppingCartIcon, TruckIcon} from "@heroicons/vue/solid";
import EmailSubscription from "../../../types/EmailSubscription";
import CountryIcon from "../../products/components/CountryIcon.vue";
import LoadingSpinnerSmall from "../../../components/LoadingSpinnerSmall.vue";

export default defineComponent({
  name: "SubscriptionCard",
  components:{
    LoadingSpinnerSmall,
    BellIcon,
    CheckCircleIcon,
    XCircleIcon,
    CountryIcon,
    PresentationChartLineIcon,
    SparklesIcon,
    ShoppingCartIcon,
    TruckIcon
  },
  props:{
    subscription:{
      type: Object as PropType<EmailSubscription>,
      required: true
    },
    isUpdating:{
      type: Boolean,
      required: false,
      default:false
    }
  },
  emits:[
    'onToggleSubscription'
  ],
  setup(props, context){
    const alertDescriptions = {
      'deals': "Daily alert with the latest deals for the given country.",
      'new-products': "Real time alert when a new product listing is added.",
      'lowest-price': "Real time alert when the lowest recorded price of a product is discovered.",
      'back-in-stock': "Real time alert when a product that was previously out of stock, is now in stock."
    }

    const toggleSubscription = (active: boolean) => {
      props.subscription.isLoading = true;
      context.emit('onToggleSubscription', {
        'subscription': props.subscription,
        'active': active
      });
    }


    return {
      alertDescriptions,
      toggleSubscription
    }
  }
})
</script>