<template>
    <component :is="'script'"
               id="tcg-a1"
              data-affcode="TCGcompare"
              :data-width="dims[adType].width"
              :data-height="dims[adType].height"
              data-category-id="3"
              data-campaign="affiliate"
              data-source="TCGcompare"
              data-medium="TCGcompare"
              src="https://content.tcg20life.com/tcgafa.js" async>
    </component>
</template>
<script lang="ts">
import {defineComponent} from "vue";

export default defineComponent({
  name: "TCGplayerAd",
  props:{
    adType:{
      type: String,
      required: true
    }
  },
  setup() {
    const dims = {
      wide: {
        width: 728,
        height: 90
      },
      tall: {
        width: 300,
        height: 250
      }
    }

    const adsEnabled = import.meta.env.VITE_GOOGLE_ADS_ENABLED;

    return {
      dims,
      adsEnabled
    }
  },
});
</script>