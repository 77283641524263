<template>
  <div v-if="adsEnabled" class="border-2 border-dotted p-1 w-100">
    <small class="text-gray-800 dark:text-gray-200">Please consider <a href="https://www.buymeacoffee.com/tcgcompare" target="_blank" title="Help us with our hosting costs" class="text-sky-400">donating</a> towards hosting costs to get rid of these ads.</small>
    <TCGplayerAd v-if="adType === 'tcgplayer' || adType === 'both'" :ad-type="'tall'"/>
    <TCGplayerAd v-if="adType === 'tcgplayer-wide' || adType === 'both-wide'" :ad-type="'wide'"/>
    <GoogleAd v-if="adType === 'google' || adType === 'both'" :adClient="adClient" />
    <GoogleAdProduct v-if="adType === 'google-wide' || adType === 'both-wide'" :adClient="adClient" />
  </div>
</template>
<script lang="ts">
import {defineComponent} from "vue";
import GoogleAd from "./GoogleAd.vue";
import TCGplayerAd from "./TCGplayerAd.vue";
import GoogleAdProduct from "./GoogleAdProduct.vue";

export default defineComponent({
  name: "AdWrapper",
  components: {GoogleAdProduct, TCGplayerAd, GoogleAd},
  props:{
    adType: {
      type: String,
      required: true
    }
  },
  setup(){
    const adsEnabled = import.meta.env.VITE_GOOGLE_ADS_ENABLED;
    const adClient = import.meta.env.VITE_GOOGLE_ADS_CLIENT;

    return {
      adClient,
      adsEnabled
    }
  }
})
</script>