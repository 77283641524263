import axios from "axios";
import { ApiAsyncWrapper, ApiResponseWrapper } from "./utils/ApiAsyncWrapper";
import ProductCategory from "../types/ProductCategory";
import ProductSubCategory from "../types/ProductSubCategory";

function listProductCategories(gameSlug: string): Promise<ApiResponseWrapper<ProductCategory[]>> {
    return ApiAsyncWrapper(axios.get(`/${gameSlug}/categories`));
}

function listProductSubCategories(gameSlug: string): Promise<ApiResponseWrapper<ProductSubCategory[]>> {
    return ApiAsyncWrapper(axios.get(`/${gameSlug}/sub-categories`));
}

export default {
    listProductCategories,
    listProductSubCategories
}
