import {defineStore} from "pinia";
import EmailSubscription from "../../types/EmailSubscription";
import SubscriptionApiClient from "../../api/SubscriptionApiClient";
import PaginatedResponse from "../../types/PaginatedResponse";
import EmailSubscriptionRequest from "../../types/EmailSubscriptionRequest";

const useSubscriptionStore = defineStore({
    id: "subscriptions",
    state: () => ({
        subscriptions: undefined as PaginatedResponse<EmailSubscription[]> | undefined
    }),
    actions: {
        async listSubscriptions(token: string) {
            const response = await SubscriptionApiClient.listSubscriptions(token);
            if(response.success){
                this.subscriptions = response.data!;
            }
        },

        async toggleSubscription(subscription: EmailSubscription, active: boolean){
            try{
                const response = await SubscriptionApiClient.toggleAlert(
                    subscription.id,
                    subscription.type,
                    subscription.country,
                    active,
                    subscription.product ? subscription.product: null
                )
                if(response.success){
                    let idx = this.subscriptions?.data.findIndex((x) => x.id == response.data.id);
                    this.subscriptions?.data.splice(idx, 1);
                    this.subscriptions?.data.push(response.data);
                }
            }
            catch (e){
                throw e;
            }
        },

        async unsubscribeAll(token: string){
            if(this.subscriptions && this.subscriptions.data){
                this.subscriptions.data = this.subscriptions.data.map(function(x) {
                    x.active = false;
                    x.isLoading = false;
                    return x;
                });
            }
            const response = await SubscriptionApiClient.unsubscribeAllAlerts(token);
            if(response.success && this.subscriptions && this.subscriptions.data){
                this.subscriptions.data = this.subscriptions.data.map(function(x) {
                    x.active = false;
                    x.isLoading = false;
                    return x;
                });
            }
        },

        async subscribe(subscriptionRequest: EmailSubscriptionRequest): Promise<EmailSubscription>{
            try{
                const response = await SubscriptionApiClient.subscribe(subscriptionRequest);
                return response.data!;
            }
            catch (e) {
                throw e;
            }
        }
    }
});

export default useSubscriptionStore;