<!-- This example requires Tailwind CSS v2.0+ -->
<template>
    <Popover class="relative inline-block text-left">
        <PopoverButton
            class="inline-flex justify-center w-full rounded-md border border-gray-300 dark:border-gray-900 shadow-sm px-2 pb-2 pt-1 mt-1 bg-white dark:bg-gray-600 text-sm font-medium text-gray-700 hover:bg-gray-100"
            :title="`Manage your alert subscriptions`">
            <BellIcon class="h-5 w-5 pt-1 text-indigo-500 dark:text-gray-200" />
            <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
        </PopoverButton>

        <PopoverPanel class="origin-top-right absolute right-0 mt-2 w-64 rounded-md shadow-lg bg-white dark:bg-gray-800 dark:text-gray-200 ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none z-101">
            <div class="p-4">
                    <span class="text-sm dark:text-gray-200">
                        Enter your email address below to receive a magic-link in order to access your alert subscriptions!
                    </span>

                <form v-if="!submissionSuccess" class="mt-4 flex flex-col items-center" @submit="onSubmit">
                    <div class="flex flex-col items-stretch">
                        <TextInput
                            id="subscription-email"
                            label="Email"
                            placeholder="Email Address"
                            type="email"
                            :value="email"
                            :errors="emailError"
                            :isEditing="true"
                            :show-label="false"
                            :show-errors="false"
                            @update:value="(value) => (email = value)"
                        />
                        <span class="text-red-600 text-xs py-1">
                              &nbsp;<strong v-if="emailError">{{emailError}}</strong>
                            </span>
                    </div>
                    <button v-if="!isSubmitting"
                            type="submit"
                            class="w-full rounded-md self-start border border-transparent bg-indigo-600 px-4 py-2 font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:text-sm"
                            @click.stop="onSubmit"
                    >
                        Submit
                    </button>
                    <div
                        v-if="isSubmitting"
                        class="inline-flex items-center justify-center rounded-b-lg border border-transparent self-start mt-0 lg:mt-2 lg:self-start text-xs">
                        <LoadingSpinnerSmall class="h-4 w-4"/>
                        <span class="text-sm dark:text-gray-200 ml-1">Submitting...</span>
                    </div>
                </form>
                <p v-if="submissionSuccess" class="text-sm text-green-500 py-4 flex flex-col items-center">
                    <CheckCircleIcon class="h-5 w-5 text-green-500" aria-hidden="true" />
                    Please check your email for a magic-link, if you haven't received anything in the next few minutes, try again.
                </p>
                <p class="text-xs dark:text-gray-400 mt-2"><strong>Note:</strong> in order to receive the magic-link you need to have subscribed to at least one alert.</p>
            </div>
        </PopoverPanel>
    </Popover>
</template>

<script lang="ts">
import {computed, defineComponent, markRaw, ref} from 'vue';
import {Menu, MenuButton, MenuItem, MenuItems, Popover, PopoverButton, PopoverPanel} from '@headlessui/vue'
import {
    BellIcon,
    ChevronDownIcon,
    CheckCircleIcon
} from '@heroicons/vue/solid'
import TextInput from "../../components/forms/TextInput.vue";
import * as yup from "yup";
import {useField, useForm} from "vee-validate";
import LoadingSpinnerSmall from "../../components/LoadingSpinnerSmall.vue";
import SubscriptionApiClient from "../../api/SubscriptionApiClient";
import emitter from "../../utils/emitter";
import NotificationType from "../../types/NotificationType";

export default defineComponent({
    name: "NotificationPanel",
    components: {
        PopoverPanel,
        PopoverButton,
        Popover,
        LoadingSpinnerSmall,
        TextInput,
        BellIcon,
        Menu,
        MenuButton,
        MenuItem,
        MenuItems,
        ChevronDownIcon,
        CheckCircleIcon
    },
    setup(){

        const isSubmitting = ref(false);
        const submissionSuccess = ref(false);

        // Form Validation Schema
        const schema = markRaw(yup.object({
            email: yup
                .string()
                .required("Please enter your email.")
                .email('Invalid Email Address')
        }));

        // Form context
        const { handleSubmit, errors, resetForm } = useForm({
            validationSchema: schema
        });
        const { errorMessage: emailError, meta: emailMeta, value: email } = useField('email');


        const onSubmit = handleSubmit(async () => {
            isSubmitting.value = true;
            try{
                let response = await SubscriptionApiClient.requestLogin(String(email.value));
                if(response.success){
                    submissionSuccess.value = true;
                }
            }
            catch (e) {
                emitter.emit('show-notification', {
                    type: NotificationType.ERROR,
                    title: "Unable to request magic-link",
                    body: e.errors.message ?? "There was an error completing your request, please try again later"
                });
            }
            isSubmitting.value = false;
        });

        return {
            isSubmitting,
            email,
            emailError,
            errors,
            onSubmit,
            submissionSuccess
        }
    }
});
</script>