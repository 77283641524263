<template>
    <div class="flex bg-indigo-700 dark:bg-gray-700 text-white dark:text-gray-100 w-full flex-col gap-2 p-2 py-4 rounded lg:rounded-none lg:rounded-r-lg">
        <div class="font-bold">Product Information:</div>
        <div class="text-xs">
            <ul>
                <li
                    v-for="(value, key) in meta"
                >
                    <strong>{{ MetaFormatter.prettyPrintKey(key) }}:</strong> {{ value }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script lang="ts">

import {defineComponent} from "vue";
import MetaFormatter from "../../../utils/MetaFormatter";

export default defineComponent({
    name: "ProductMeta",
    props:{
      meta: {
          type: Object,
          required:true
      }
    },
    setup() {
        return {
            MetaFormatter
        }
    }
})
</script>