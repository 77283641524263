<template>
    <LoadingSpinner />
</template>
<script lang="ts">
import {defineComponent, onBeforeMount} from "vue";
import LoadingSpinner from "../../../components/LoadingSpinner.vue";
import AuthApiClient from "../../../api/AuthApiClient";
import {useRoute, useRouter} from "vue-router";
import emitter from "../../../utils/emitter";
import NotificationType from "../../../types/NotificationType";

export default defineComponent({
    name: "VerifyEmail",
    components: {LoadingSpinner},
    setup(){
        const route = useRoute();
        const router = useRouter();

        onBeforeMount(async () => {
            try{
                if(route.params.token){
                    const response = await AuthApiClient.verifyEmail(String(route.params.token));
                    if(response.success){
                        emitter.emit('show-notification', {
                            type: NotificationType.SUCCESS,
                            title: "Email address verified",
                            body: "Redirecting you to the home page..."
                        });
                    }
                    else
                    {
                        throw new Error("Something went wrong, please try again later.");
                    }
                }
                else
                {
                    throw new Error("No verification token provided, please use the correct link.");
                }

                setTimeout(async () => {
                    await router.push("/");
                }, 2000);
            }
            catch (e){
                emitter.emit('show-notification', {
                    type: NotificationType.ERROR,
                    title: "Unable to Verify Email Address",
                    body: e.errors.message ?? "There was an error completing your request, please try again later"
                });
                setTimeout(async () => {
                    await router.push("/");
                }, 2000);
            }
        });
        return {}
    }
})
</script>