import axios from "axios";
import { ApiAsyncWrapper, ApiResponseWrapper } from "./utils/ApiAsyncWrapper";
import Game from "../types/Game";

function listGames(paginationUrl?: string): Promise<ApiResponseWrapper<Game[]>> {
    return ApiAsyncWrapper(axios.get(paginationUrl ? paginationUrl : '/games'));
}

export default {
    listGames
}
