<template>
    <div class="ml-auto mr-auto">
        <component :is="'script'" async :src="`https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${adClient}`"
                   crossorigin="anonymous"></component>
        <ins class="adsbygoogle"
             style="display:block"
             data-ad-format="autorelaxed"
             :data-ad-client="adClient"
             data-ad-slot="3237632073"></ins>
        <component :is="'script'">
            (adsbygoogle = window.adsbygoogle || []).push({});
        </component>
    </div>
</template>
<style>
ins.adsbygoogle[data-ad-status="unfilled"] {
    display: none !important;
}
ins.adsbygoogle {
    margin-bottom:5px;
}
</style>
<script lang="ts">
import {defineComponent} from "vue";

export default defineComponent({
    name: "GoogleAd",
    props:{
      adClient:{
        type: String,
        required: true
      }
    },
    setup() {
      return {

      }
    },
});
</script>