<template>
    <footer class="bg-white dark:bg-gray-800">
        <div class="max-w-7xl mx-auto py-1 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
            <div class="mt-1 md:mt-0 md:order-1 flex flex-col md:flex-row">
                <p class="text-[10px] text-gray-400 md:basis-2/3">The information presented on this website about the Pokémon Trading Card Game, including images and text, is copyright of The Pokémon Company, Nintendo, Game Freak, Creatures and/or Wizards of the Coast. This website is not produced by, endorsed by, supported by, or affiliated with any of these companies. *TCGCompare is supported by it's audience, when you purchase through links on our site, we may earn an affiliate commission.</p>
                <p class="text-[10px] text-gray-400 md:basis-1/3 sm:text-right flex flex-col">
                    <span>
                    &copy; {{ date.getFullYear() }} TCGCompare. All rights reserved.
                    </span>
                    <span>
                        <router-link to="/cookies">Cookies</router-link>
                    </span>
                    <span>
                        <router-link to="/privacy">Privacy</router-link>
                    </span>
                </p>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    setup() {
        const date = new Date();
        return {
            date,
        }
    },
}
</script>