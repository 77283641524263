<template>
    <teleport to="head">
        <link rel="canonical" :href="pageUrl" />
        <meta property="robots" content="noindex" />
    </teleport>
    <div style="display: flex; flex-direction: column; align-items: center; justify-content: center"
         class="h-full flex overflow-hidden dark:bg-gray-800 dark:text-gray-200">
        <div style="display: flex; align-items: center; flex-direction: column">
            <div class="text-center text-xl mt-2">Page Not Found</div>
            <div class="text-center text-sm mt-5">Please <router-link to="/" class="text-indigo-300 cursor-pointer">click here</router-link> to go back to the home page.</div>
        </div>
    </div>
</template>

<script>

import {defineComponent} from "vue";
export default defineComponent( {
    name: "404Page",
    setup(){
        return{
            pageUrl: window.location.href
        }
    }
});
</script>

<style scoped>

</style>
