<template>
    <div class="bg-white dark:bg-gray-800 px-2 sm:px-0 py-3 flex items-center justify-between border-t border-gray-200 dark:border-gray-600">
        <div v-if="paging.total > 0" class="flex-1 flex flex-col items-center justify-between">
            <div>
                <p class="text-gray-700 dark:text-gray-200">
                    Showing
                    <span class="font-medium">{{ paging.from }}</span>
                    to
                    <span class="font-medium">{{ paging.to }}</span>
                    of
                    <span class="font-medium">{{ paging.total }}</span>
                    results
                </p>
            </div>
            <div class="hidden sm:block mt-2 md:mt-0">
                <nav class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                    <button
                        @click="linkClicked(link)"
                        v-for="link in paging.links"
                        :key="link"
                        v-show="link.url"
                        class="bg-white dark:bg-black border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border font-medium"
                        :class="{ 'bg-indigo-700 text-white': link.active }"
                    >
                        <span v-html="link.label"></span>
                    </button>
                </nav>
            </div>
            <div class="block sm:hidden mt-2 md:mt-0">
                <nav class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                    <button
                        @click="linkClicked(link)"
                        v-for="link in prevAndNextLinks"
                        :key="link"
                        v-show="link.url"
                        class="bg-white dark:bg-black border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border font-medium"
                        :class="{ 'bg-indigo-700 text-white': link.active }"
                    >
                        <span v-html="link.label"></span>
                    </button>
                </nav>
            </div>
        </div>
        <div v-else class="flex-1 flex flex-col md:flex-row items-center justify-between text-gray-400 py-4 text-xl">
            No results found.
        </div>
    </div>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from "vue";
import Paginateable from "../types/Paginateable";
import PagingLink from "../types/PagingLink";

export default defineComponent({
    props: {
        paging: {
            type: Object as PropType<Paginateable>,
            required: true
        }
    },
    emits: ["linkClicked"],
    setup(props, context) {
        const prevAndNextLinks = computed(() => {
            return [
                {
                    "active": false,
                    "url": props.paging.prev_page_url,
                    "label": "&laquo; Previous"
                },
                {
                    "active": false,
                    "url": props.paging.next_page_url,
                    "label": "Next &raquo;"
                }
            ] as PagingLink[]
        });

        function linkClicked(link: PagingLink) {
            context.emit("linkClicked", link);
        }

        return {
            prevAndNextLinks,
            linkClicked
        }
    }
})
</script>
