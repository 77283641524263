import {defineStore} from "pinia";
import ProductCategory from "../../types/ProductCategory";
import ProductCategoryClient from "../../api/ProductCategoryApiClient";
import ProductSubCategory from "../../types/ProductSubCategory";

const useCategoryStore = defineStore({
    id: "categories",
    state: () => ({
        categories: [] as ProductCategory[],
        subCategories: [] as ProductSubCategory[]
    }),
    actions: {
        async listCategories(gameSlug: string) {
            const response = await ProductCategoryClient.listProductCategories(gameSlug);
            if(response.success){
                this.categories = response.data!;
            }
        },
        async listSubCategories(gameSlug: string) {
            const response = await ProductCategoryClient.listProductSubCategories(gameSlug);
            if(response.success){
                this.subCategories = response.data!;
            }
        },
        getCategoryNameBySlug(slug: string){
            return this.categories.find(x => x.slug === slug)?.name;
        },
        getCategoryBySlug(slug: string){
            return this.categories.find(x => x.slug === slug);
        },
        getSubCategoryBySlug(slug: string){
            return this.subCategories.find(x => x.slug === slug);
        },
        getSubCategoryNameBySlug(slug: string){
            return this.subCategories.find(x => x.slug === slug)?.name;
        }
    }
});

export default useCategoryStore;