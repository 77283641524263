<template>
    <label :for="id" class="block font-medium text-gray-700 dark:text-gray-100"
        :class="{
            'sr-only': !showLabel
        }"
    >
        {{ label }}{{ required && isEditing ? "*" : "" }}
    </label>
    <div v-if="isEditing" class="mt-1 sm:mt-0 col-span-2">
        <input
            :step="step"
            :min="type === 'number' ? minNumber : undefined"
            :max="type === 'number' ? maxNumber : undefined"
            :type="type"
            :name="label"
            :id="id"
            v-model="internalValue"
            :placeholder="placeholder"
            :disabled="disabled"
            class="block w-full shadow-sm focus:ring-sky-blue-200 focus:border-sky-blue-500 border-gray-300 dark:border-gray-800 rounded-md text-sm dark:bg-gray-500 dark:text-gray-100 dark:placeholder-gray-300"
            :class="{ 'sm:max-w-xs': !expand }"
            @focus="onFocus"
        >
        <span v-if="showErrors" class="text-red-600 text-xs">{{errors}}</span>
    </div>
    <label v-else-if="displayValue || value" class="block text-gray-700 dark:text-gray-100">
        {{ displayValue ?? value }}
    </label>
    <label v-else-if="nullText" class="block font-normal text-gray-400 dark:text-gray-100">
        {{ nullText }}
    </label>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "vue";

export default defineComponent({
    name: "TextInput",
    props:{
        label:{
            required: true,
            type: String,
        },
        value:{
            required: false,
            type: [String, Number] as PropType<string | number | undefined>,
            default: undefined
        },
        errors:{
            required: false,
            type: String,
        },
        placeholder:{
            required: false,
            type: String,
            default: ''
        },
        isEditing: {
            required: false,
            type: Boolean,
            default: false,
        },
        id:{
            required: true,
            type: String
        },
        type: {
            required: false,
            type: String,
            default: 'text'
        },
        displayValue: {
            required: false,
            type: String
        },
        required: {
            required: false,
            type: Boolean,
            default: false
        },
        disabled: {
            required: false,
            type: Boolean,
            default: false
        },
        expand: {
            required: false,
            type: Boolean,
            default: false
        },
        step: {
            required: false,
            type: Number,
            default: 0.01
        },
        minNumber: {
            required: false,
            type: Number,
            default: 0
        },
        maxNumber: {
            required: false,
            type: Number,
            default: 10000
        },
        nullText: {
            required: false,
            type: String,
            default: "Not Specified"
        },
        showLabel: {
            required: false,
            type: Boolean,
            default: true
        },
        showErrors: {
            required: false,
            type: Boolean,
            default: true
        }
    },
    emits:[
        'update:value',
        'onFocus'
    ],
    setup(props,context){
        const internalValue = computed({
            get: () => {
                return props.value;
            },
            set: (newValue) => {
                context.emit('update:value', newValue);
            }
        });

        const onFocus = function() {
            context.emit('onFocus');
        }

        return {
            internalValue,
            onFocus
        }
    }
})
</script>

<style scoped>

</style>
