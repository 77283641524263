<template>
    <div class="bg-white dark:bg-gray-700 rounded">
        <div>
            <!-- Mobile filter dialog -->
            <TransitionRoot as="template" :show="filterPanelOpen">
                <Dialog as="div" class="relative z-40 lg:hidden" @close="handleFilterButtonClick">
                    <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
                        <div class="fixed inset-0 bg-black bg-opacity-25" />
                    </TransitionChild>

                    <div class="fixed inset-0 flex z-40">
                        <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="translate-x-full">
                            <DialogPanel class="ml-auto relative max-w-xs w-full h-full bg-white dark:bg-gray-700 shadow-xl py-4 pb-6 flex flex-col overflow-y-auto">
                                <div class="px-4 flex items-center justify-between">
                                    <h2 class="text-lg font-medium text-gray-900 dark:text-gray-200">Filters</h2>
                                    <button type="button" class="-mr-2 w-10 h-10 p-2 flex items-center justify-center text-gray-400 hover:text-gray-500" @click="handleFilterButtonClick">
                                        <span class="sr-only">Close menu</span>
                                        <XIcon class="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>

                                <!-- Filters -->
                                <form class="mt-4">
                                    <Disclosure as="div" v-for="section in filters" :default-open="true" :key="section.name" class="" v-slot="{open}">
                                        <DisclosureButton v-if="section.options.length>0" class="w-full p-2 flex items-center justify-between text-gray-400 hover:text-gray-500">
                                                  <span class="text-sm font-medium text-gray-900 dark:text-gray-200">
                                                    {{ section.name }}
                                                  </span>
                                            <span class="ml-6 h-7 flex items-center">
                                                    <ChevronDownIcon :class="[open ? '-rotate-180' : 'rotate-0', 'h-5 w-5 transform']" aria-hidden="true" />
                                                  </span>
                                        </DisclosureButton>
                                        <DisclosurePanel v-if="section.options.length>0" as="div" class="">
                                            <div class="pt-4 pb-2 px-4">
                                                <div class="space-y-6">
                                                    <div v-for="(option, optionIdx) in section.options" :key="option.value"
                                                         class="flex items-center">
                                                        <input :id="`filter-${section.id}-${optionIdx}-mobile`"
                                                               :name="`${section.id}[]`"
                                                               :value="option.value"
                                                               type="checkbox"
                                                               v-model="option.checked"
                                                               :checked="option.checked"
                                                               class="h-4 w-4 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500 cursor-pointer"
                                                               @change="handleOptionChange"
                                                        />
                                                        <label :for="`filter-${section.id}-${optionIdx}`"
                                                               class="ml-3 text-sm text-gray-600 dark:text-gray-200 cursor-pointer">
                                                            {{ option.label }}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </DisclosurePanel>
                                    </Disclosure>
                                </form>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </Dialog>
            </TransitionRoot>

            <section class="max-w-2xl mx-auto sm:py-4 sm:px-6 lg:max-w-7xl lg:px-8">

                <div class="">
                    <aside>
                        <h2 class="text-gray-800 dark:text-gray-200">Filters</h2>
                        <div class="hidden lg:block">
                            <form class=" space-y-4">
                                <Disclosure
                                    as="div"
                                    v-for="section in filters"
                                    :default-open="true"
                                    :key="section.name"
                                    class=""
                                    v-slot="{open}"
                                >
                                    <DisclosureButton v-if="section.options.length>0" class="w-full pt-2 pb-2 flex items-center justify-between text-gray-400 hover:text-gray-500">
                                      <span class="text-lg font-medium text-gray-900 dark:text-gray-200">
                                        {{ section.name }}
                                      </span>
                                        <span class="ml-6 h-7 flex items-center">
                                        <ChevronDownIcon :class="[open ? '-rotate-180' : 'rotate-0', 'h-5 w-5 transform']" aria-hidden="true" />
                                      </span>
                                    </DisclosureButton>
                                    <DisclosurePanel v-if="section.options.length>0" as="div" class="">
                                        <div class="pt-2 space-y-2">
                                            <div v-for="(option, optionIdx) in section.options" :key="option.value"
                                                 class="flex items-center">
                                                <input :id="`filter-${section.id}-${optionIdx}`"
                                                       :name="`${section.id}[]`"
                                                       :value="option.value"
                                                       type="checkbox"
                                                       v-model="option.checked"
                                                       :checked="option.checked"
                                                       class="h-4 w-4 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500 cursor-pointer"
                                                       @change="handleOptionChange"
                                                />
                                                <div class="inline-flex">
                                                    <SiteIcon
                                                        class="flex items-center ml-2 justify-content-around"
                                                        v-if='section.name === "Site"'
                                                        :name="option.name ?? ''"
                                                        :url="option.image_path"
                                                        size="small"
                                                    />
                                                    <label :for="`filter-${section.id}-${optionIdx}`"
                                                           class="ml-2 text-sm text-gray-600 dark:text-gray-200 cursor-pointer">
                                                        {{ option.label }}
                                                    </label>
                                                    <CountryIcon v-if='section.name === "Country"' :code="option.label" class="ml-2"/>
                                                </div>
                                            </div>
                                        </div>
                                    </DisclosurePanel>
                                </Disclosure>

                            </form>
                        </div>
                    </aside>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import { ref, defineComponent } from 'vue'
import {
    Dialog,
    DialogPanel,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    TransitionChild,
    TransitionRoot,
} from '@headlessui/vue'
import { XIcon } from '@heroicons/vue/outline'
import { ChevronDownIcon, PlusSmIcon } from '@heroicons/vue/solid'
import CountryIcon from "../../products/components/CountryIcon.vue";
import SiteIcon from "../../products/components/SiteIcon.vue";

export default defineComponent({
    name: "Filters",
    components: {
        SiteIcon,
        CountryIcon,
        PlusSmIcon,
        ChevronDownIcon,
        Dialog,
        DialogPanel,
        Disclosure,
        DisclosureButton,
        DisclosurePanel,
        TransitionChild,
        TransitionRoot,
        XIcon
    },
    emits: [
        'onOptionsChange',
        'onFiltersOpened'
    ],
    props: {
        filterPanelOpen:{
            type: Boolean,
            required: false,
            default: false
        },
        filters: {
            type: Array,
            required: false,
            default: []
        }
    },
    setup(props, context) {
        const open = ref(false)

        const filters = ref(props.filters);
        const isFilterPanelOpen = ref(props.filterPanelOpen);

        const handleOptionChange = () => {
            context.emit('onOptionsChange', filters.value);
        }

        const handleFilterButtonClick = () => {
            context.emit('onFiltersOpened', false);
        }

        return {
            open,
            handleOptionChange,
            handleFilterButtonClick,
            isFilterPanelOpen,
            filters
        }
    },
});
</script>