<template>
  <AdWrapper ad-type="tcgplayer" />
  <div v-if="products" v-for="(product, idx) in products.data" :key="idx" class="group relative">
    <CategoryProduct
        :categorySlug="product.category.slug"
        :gameSlug="gameSlug"
        :product="product" />
  </div>
  <AdWrapper ad-type="google-product" class="group relative"/>
</template>
<script lang="ts">
import {computed, defineComponent, PropType} from "vue";
import Product from "../../../types/Product";
import CategoryProduct from "./CategoryProduct.vue";
import AdWrapper from "../../../components/AdWrapper.vue";
import PaginatedResponse from "../../../types/PaginatedResponse";

export default defineComponent({
  name: "CategoryProductsWithAdsList",
  components: {
    AdWrapper,
    CategoryProduct,
  },
  props:{
    products:{
      type: Object as PropType<PaginatedResponse<Product>>,
      required: false
    },
    gameSlug:{
      type: String,
      required: true
    }
  },
  setup(props){
    const productList = computed(() => {
      props.products
    });

    return {
      productList
    }
  }
})
</script>