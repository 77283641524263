<template>

    <div class="bg-white dark:bg-gray-700 shadow">
        <div class="px-4 pt-4 pb-2 sm:px-4 sm:pb-0">
            <h3 class="text-sm font-medium leading-6 text-gray-900 dark:text-gray-200">
                <slot name="title"></slot>
            </h3>
            <div class="max-w-xl text-xs text-gray-500 dark:text-gray-300">
                <slot name="description"></slot>
            </div>
            <form v-if="!showEmailNeedsVerifyingMessage && !showSuccessMessage" class="mt-4 flex flex-col sm:flex-row sm:items-center gap-x-2">
                    <div class="flex flex-col">
                        <TextInput
                            id="subscription-name"
                            label="Name"
                            placeholder="Name"
                            type="text"
                            :value="name"
                            :errors="nameError"
                            :isEditing="true"
                            :show-label="false"
                            :show-errors="false"
                            @update:value="(value) => (name = value)"
                        />
                        <span class="text-red-600 text-xs sm:col-span-2">
                          &nbsp;<strong v-if="nameError">{{nameError}}</strong>
                        </span>
                    </div>
                    <div class="flex flex-col">
                        <TextInput
                            id="subscription-email"
                            label="Email"
                            placeholder="Email Address"
                            type="email"
                            :value="email"
                            :errors="emailError"
                            :isEditing="true"
                            :show-label="false"
                            :show-errors="false"
                            @update:value="(value) => (email = value)"
                        />
                        <span class="text-red-600 text-xs sm:col-span-2">
                          &nbsp;<strong v-if="emailError">{{emailError}}</strong>
                        </span>
                    </div>
                    <button v-if="!isSubscribing"
                            type="submit"
                            class="rounded-md self-start border border-transparent bg-indigo-600 px-4 py-2 font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                            @click.stop="onSubmit"
                    >
                        Subscribe
                    </button>
                    <div
                        v-if="isSubscribing"
                        class="inline-flex items-center justify-center rounded-b-lg border border-transparent self-start mt-0 lg:mt-2 lg:self-start text-xs">
                      <LoadingSpinnerSmall class="h-4 w-4"/>
                      <span class="text-sm dark:text-gray-200 ml-1">Subscribing...</span>
                    </div>
            </form>
            <p v-else-if="showEmailNeedsVerifyingMessage" class="text-sm text-green-500 py-4 flex flex-row">
                <ExclamationCircleIcon class="h-12 w-12 text-orange-500 mr-1" aria-hidden="true" />
                <span>Thanks for subscribing! One more step - your email address <strong>needs verifying</strong>, please check your email for a verification link.</span>
            </p>
            <p v-else class="text-sm text-green-500 py-4 flex flex-row">
                <CheckCircleIcon class="h-5 w-5 text-green-500" aria-hidden="true" />
                Thanks for subscribing!
            </p>
        </div>
    </div>
</template>
<script lang="ts">
import {defineComponent, markRaw, PropType, ref, watch} from "vue";
import {CheckCircleIcon, ExclamationCircleIcon} from "@heroicons/vue/solid";
import * as yup from "yup";
import {useField, useForm} from "vee-validate";
import TextInput from "./forms/TextInput.vue";
import SubscriptionPanelSubmission from "../types/SubscriptionPanelSubmission";
import Country from "../types/Country";
import LoadingSpinnerSmall from "./LoadingSpinnerSmall.vue";

export default defineComponent({
    name: "SubscriptionPanel",
    components: {
        CheckCircleIcon,
        ExclamationCircleIcon,
        LoadingSpinnerSmall,
        TextInput
    },
    props: {
        visible: {
            type: Boolean,
            required: true
        },
        title: {
            type: String,
            required: false,
            default: undefined
        },
        subscriptionType:{
            type: String,
            required:true
        },
        country:{
            type: Object as PropType<Country>,
            required: false,
            default: undefined
        },
        showEmailNeedsVerifyingMessage:{
            type: Boolean,
            required: false,
            default: false
        },
        showSuccessMessage:{
            type: Boolean,
            required: false,
            default: false
        },
        isSubscribing:{
            type: Boolean,
            required: false,
            default: false
        }
    },
    emits:[
      'onSubscribe'
    ],
    setup(props, context) {

        // Form Validation Schema
        const schema = markRaw(yup.object({
            name: yup
                .string()
                .required("Please enter your name."),
            email: yup
                .string()
                .required("Please enter your email.")
                .email('Invalid Email Address')
        }));

        // Form context
        const { handleSubmit, errors, resetForm } = useForm({
            validationSchema: schema
        });

        const { errorMessage: nameError, meta: nameMeta, value: name } = useField('name');
        const { errorMessage: emailError, meta: emailMeta, value: email } = useField('email');

        const onSubmit = handleSubmit(() => {
            context.emit("onSubscribe", {
                name: String(name.value),
                email: String(email.value),
                country: props.country,
                subscriptionType: props.subscriptionType
            } as SubscriptionPanelSubmission);
        });

        return {
            onSubmit,
            email,
            emailError,
            emailMeta,
            name,
            nameError,
            nameMeta,
            errors,
        }
    }
})
</script>