import { createWebHistory, createRouter } from "vue-router";
import Home from "../modules/home/pages/Home.vue";
import CookiePolicy from "../modules/home/pages/CookiePolicy.vue";
import PrivacyPolicy from "../modules/home/pages/PrivacyPolicy.vue";
import Category from "../modules/categories/pages/Category.vue";
import CategoryList from "../modules/categories/pages/CategoryList.vue";
import Product from "../modules/products/pages/Product.vue";
import Latest from "../modules/products/pages/Latest.vue";
import VerifyEmail from "../modules/misc/pages/VerifyEmail.vue";
import ManageSubscriptions from "../modules/subscriptions/pages/ManageSubscriptions.vue";
import FourZeroFourPage from "../404.vue";
import OfflinePage from "../Offline.vue";
import ErrorPage from "../ErrorPage.vue";

const routes = [
    {
        path: "/",
        name: "home",
        component: Home
    },
    {
        path: "/privacy",
        name: "privacy",
        component: PrivacyPolicy
    },
    {
        path: "/cookies",
        name: "cookies",
        component: CookiePolicy
    },
    {
        path: "/:game",
        name: "game-category-list",
        component: CategoryList
    },
    {
        path: "/:game/:categorySlug",
        name: "game-category",
        component: Category,
        meta: {layout: "withfilterspanel-layout"},
    },
    {
        path: "/:game/latest-products",
        name: "latest-products",
        component: Latest,
        meta: {layout: "withfilterspanel-layout"},
    },
    {
        path: "/:game/latest-deals",
        name: "latest-deals",
        component: Latest,
        meta: {layout: "withfilterspanel-layout"},
    },
    {
        path: "/:game/back-in-stock-products",
        name: "back-in-stock-products",
        component: Latest,
        meta: {layout: "withfilterspanel-layout"},
    },
    {
        path: "/:game/:categorySlug/:productSlug/:lang?",
        name: "game-product",
        component: Product
    },
    {
        path: "/subscriptions/:token",
        name: "manage-subscriptions",
        component: ManageSubscriptions
    },
    {
        path: "/verify-my-email/:token",
        name: "verify-email",
        component: VerifyEmail
    },
    {
        path: '/404',
        component: FourZeroFourPage
    },
    {
        path: '/offline',
        name: "offline",
        component: OfflinePage
    },
    {
        path: "/error",
        name: "error",
        component: ErrorPage
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});


export default router;
