import axios from "axios";
import { ApiAsyncWrapper, ApiResponseWrapper } from "./utils/ApiAsyncWrapper";
import Country from "../types/Country";
import useLocalStorageStore from "../utils/useLocalStorageStore";

function getCountries(): Promise<ApiResponseWrapper<Country[]>> {
    const store = useLocalStorageStore();

    return ApiAsyncWrapper(axios.get(`/countries`, {
        params: {
            code: store.getAvailableLanguages
        }
    }));
}

export default {
    getCountries
}
