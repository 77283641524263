const fathomEnabled = import.meta.env.VITE_ENABLE_FATHOM === "true";
const fathomSiteId = <string>import.meta.env.VITE_FATHOM_SITE_ID ?? "";

/**
 * Attempt to install fathom if the env variable is enabled
 */
export function installFathom(){
    if(window.fathom) return;
    if(fathomEnabled){
        const plugin = document.createElement("script");
        plugin.setAttribute(
            "src",
            "https://cdn.usefathom.com/script.js"
        );
        plugin.setAttribute(
            "data-site",
            fathomSiteId
        );
        plugin.defer = true;
        document.head.appendChild(plugin);
    }
}

/**
 * Attempt to fire and event to fathom if the env variable is enabled
 * @param eventName
 */
type fathomEvent = {
    [key: string]: string
}

export function sendFathomEvent(eventName : string){
    // Check if not installed and attempt install
    if(!window.fathom) {
        installFathom();
    }

    // This could still be false as cookies disabled so dont attempt to send
    if(!window.fathom) return;

    window.fathom.trackEvent(eventName);

}
