<template>
    <teleport to="head">
        <link rel="canonical" :href="pageUrl" />
        <meta property="og:title" content="TCG Compare" />
        <meta property="og:description" :content="`Compare pricing and availability on thousands of TCG products.`" />
        <meta name="description" :content="`Compare pricing and availability on thousands of TCG products.`" />
        <meta property="og:image" :content="logoUrl" />
    </teleport>
    <div class="relative">
        <div class="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100 dark:bg-black" />
        <div class="mx-auto sm:px-6 lg:px-2">
            <div class="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
                <div class="absolute inset-0">
                    <img class="h-full w-full object-cover" :src="homebackdrop" alt="Pile of trading cards" />
                    <div class="absolute inset-0 bg-gradient-to-r from-purple-800 to-indigo-700 mix-blend-multiply" />
                </div>
                <div class="relative px-4 py-16 sm:px-6 sm:py-12 lg:px-8 mb-2">
                    <div class="flex items-center justify-center mb-10">
                        <img class="h-64 w-auto" :src="logo" alt="Image of Logo"/>
                    </div>
                    <h1 class="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
                        <span class="block text-white">Why Pay <span class="text-indigo-200">More?</span></span>
                    </h1>
                    <p class="mt-6 max-w-lg mx-auto text-center text-xl text-indigo-200 sm:max-w-3xl">We search the most reputable TCG retailers available to give you the best options when looking for your next purchase.</p>
                    <p class="mt-6 max-w-lg mx-auto text-center text-xl text-indigo-200 sm:max-w-3xl">More games, products, and prices are added daily.</p>
                    <div class="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
                        <div class="space-y-4 sm:space-y-0 sm:mx-auto sm:inline-grid sm:grid-cols-1 sm:gap-5">
                            <a href="/pokemon-tcg" class="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm bg-indigo-900 text-white hover:bg-indigo-800 sm:px-8">
                                See Products
                            </a>
                        </div>
                    </div>
                    <p class="mt-2 max-w-lg mx-auto text-center text-xs text-indigo-200 sm:max-w-3xl">*TCGCompare is supported by it's audience, when you purchase through links on our site, we may earn an affiliate commission.</p>
                    <div>
                        <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
                            <div v-for="item in stats"
                                 :key="item.slug"
                                 class="overflow-hidden rounded-lg bg-white dark:bg-gray-800 opacity-90 px-4 py-5 shadow sm:p-6 text-center"
                            >
                                <dt class="truncate text-sm font-medium text-gray-500 dark:text-gray-200">{{ item.name }}</dt>
                                <dd class="mt-1 text-3xl font-semibold tracking-tight text-gray-900 dark:text-gray-100 flex flex-row gap-x-2 items-center justify-center">
                                    <component :is="item.icon" class="w-10"></component>
                                    <number
                                    :ref="item.slug"
                                    :from="0"
                                    :to="item.value"
                                    :format="theFormat"
                                    :duration="2"
                                    :delay="1"
                                    easing="Power1.easeOut"/>
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { MenuIcon, XIcon, ShoppingCartIcon, GlobeIcon } from '@heroicons/vue/outline'
import logo from "/logo.svg";
import homebackdrop from "/backdrop.jpg";
import useTitleManager from "../../../utils/useTitleManager";
import {onBeforeMount, defineComponent, ref} from "vue";
import useMiscInformationStore from "../../../utils/useMiscInformationStore";
import StorefrontIcon from "../../../components/StorefrontIcon.vue";

const navigation = [
    { name: 'Product', href: '#' },
    { name: 'Features', href: '#' },
    { name: 'Marketplace', href: '#' },
    { name: 'Company', href: '#' },
]

export default defineComponent({
    name: "Home",
    components: {
        StorefrontIcon,
        Popover,
        PopoverButton,
        PopoverPanel,
        ShoppingCartIcon,
        MenuIcon,
        XIcon,
        GlobeIcon,
    },
    setup() {
        const { setTitlePrefix } = useTitleManager();

        const miscInformationStore = useMiscInformationStore();

        const theFormat = (number) => {
            return number.toFixed(0);
        }

        const stats = ref([]);
        onBeforeMount(async () => {
            setTitlePrefix("Why Pay More?");
            await miscInformationStore.loadStats();
            stats.value = miscInformationStore.stats;

        })
        return {
            logo,
            homebackdrop,
            navigation,
            pageUrl: window.location.href,
            logoUrl: window.location.href+"logo.svg",
            stats,
            theFormat
        }
    },
});
</script>