<template>
    <div class="bg-white dark:bg-gray-800 top-0 left-0 fixed h-screen w-screen flex items-center justify-center z-10">

        <div class="loading-spinner-wrapper align-middle">
            <div class="loading-spinner">
                <div></div>
                <div></div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "LoadingSpinner",
    data() {
        return {}
    }
}
</script>
<style>
@keyframes loading-spinner {
    0% {
        top: 96px;
        left: 96px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 18px;
        left: 18px;
        width: 156px;
        height: 156px;
        opacity: 0;
    }
}

.loading-spinner div {
    position: absolute;
    border-width: 4px;
    border-style: solid;
    opacity: 1;
    border-radius: 50%;
    animation: loading-spinner 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.loading-spinner div:nth-child(1) {
    border-color: #420457;
    animation-delay: 0s;
}

.loading-spinner div:nth-child(2) {
    border-color: #5a3faa;
    animation-delay: -0.5s;
}

.loading-spinner-wrapper {
    width: 200px;
    height: 200px;
    display: inline-block;
    overflow: hidden;
    background: rgba(NaN, NaN, NaN, 0);
}

.loading-spinner {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
}

.loading-spinner div {
    box-sizing: content-box;
}
</style>