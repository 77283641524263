<template>
    <div class="col-span-1 flex items-center justify-center">
        <span class="atext-sm flex flex-row items-center py-1 px-2 rounded-full last:mr-0 mr-1"
              :class="{'text-white bg-green-700': subscription.active, 'text-white bg-red-700': !subscription.active}">
            <CheckCircleIcon v-if="subscription.active" class="w-4" />
            <XCircleIcon v-if="!subscription.active" class="w-4" />
            <span class="ml-1 text-sm">{{ subscription.active ? 'Active' : 'Disabled' }}</span>
        </span>
    </div>
    <div class="col-span-1 flex items-center justify-center">
        <CountryIcon class="mt-0 mr-0" size="large" :code="subscription.country.code" />
    </div>
    <div class="col-span-1 lg:col-span-2 flex items-center justify-start">
        <ShoppingCartIcon v-if="subscription.type.slug == 'deals'" class="h-8 text-indigo-700 dark:text-gray-200" />
        <SparklesIcon v-if="subscription.type.slug == 'new-products'" class="h-8 text-indigo-700 dark:text-gray-200" />
        <PresentationChartLineIcon v-if="subscription.type.slug == 'lowest-price'" class="h-8 text-indigo-700 dark:text-gray-200 -scale-x-100" />
        <TruckIcon v-if="subscription.type.slug == 'back-in-stock'" class="h-8 text-indigo-700 dark:text-gray-200" />
        <h3 class="mt-1 text-sm font-medium text-gray-900 dark:text-gray-300">{{ subscription.type.name }}</h3>
    </div>
    <div class="col-span-3 lg:col-span-7 flex flex-col lg:flex-row items-center justify-between">

        <div v-if="subscription.product">
                <dt class="sr-only">Product Name</dt>
                <router-link v-if="subscription.product" :to="`/${subscription.product.category.game.slug}/${subscription.product.category.slug}/${subscription.product.slug}`" :title="subscription.product.name">
                    <dd class="mt-0 text-sm text-gray-500 dark:text-gray-200 flex flex-col md:flex-row">
                        <img class="mx-auto h-16 w-16 flex-shrink-0 rounded-full" :src="subscription.product?.image_path" alt="" />
                        <div class="flex flex-col justify-center">
                            <span class="mt-2 md:mt-0 self-center sm:ml-2 text-left">{{ subscription.product?.name }}</span>
                            <div class="flex grow-0 visible xl:invisible">
                                <span class="rounded-full bg-indigo-700 px-2 py-1 text-sm font-medium text-gray-100">{{ subscription.product?.category.name }}</span>
                            </div>
                        </div>
                    </dd>
                </router-link>
        </div>
        <div v-if="subscription.product" class="h-0 lg:h-auto invisible xl:visible">

            <router-link v-if="subscription.product" :to="`/${subscription.product.category.game.slug}/${subscription.product.category.slug}`" :title="subscription.product.category.name">
                <dt class="sr-only">Category</dt>
                <dd class="mt-2 md:mt-0">
                    <span class="rounded-full bg-indigo-700 px-2 py-1 text-sm font-medium text-gray-100">{{ subscription.product?.category.name }}</span>
                </dd>
            </router-link>
        </div>
        <div v-else>
            <p class="text-gray-500 text-sm">This alert tracks all products for the given country.</p>
        </div>
    </div>
    <div class="col-span-3 lg:col-span-1 flex items-center pr-2">
            <button
                v-if="!subscription.isLoading && !isUpdating"
                class="inline-flex w-0 flex-1 items-center justify-center rounded-b-lg border border-transparent py-4 text-sm font-medium text-gray-700 dark:text-gray-200 hover:text-gray-500 dark:hover:text-gray-500"

                :title="`${subscription.active ? 'Disable' : 'Enable' } this alert.`"
                @click="toggleSubscription(!subscription.active)"
            >
                <CheckCircleIcon v-if="!subscription.active" class="h-5 w-5 text-green-500" aria-hidden="true" />
                <XCircleIcon v-if="subscription.active" class="h-5 w-5 text-red-500" aria-hidden="true" />
                <span class="ml-1">{{ subscription.active ? 'Disable' : 'Enable' }}</span>
            </button>
            <div
                v-if="subscription.isLoading || isUpdating"
                class="inline-flex w-0 flex-1 items-center justify-center rounded-b-lg border border-transparent py-4 text-sm">
                <LoadingSpinnerSmall class="h-4 w-4" />
            </div>
    </div>
</template>
<script lang="ts">
import {defineComponent, PropType, ref} from "vue";
import {CheckCircleIcon, XCircleIcon, BellIcon, PresentationChartLineIcon, SparklesIcon, ShoppingCartIcon, TruckIcon} from "@heroicons/vue/solid";
import EmailSubscription from "../../../types/EmailSubscription";
import CountryIcon from "../../products/components/CountryIcon.vue";
import LoadingSpinnerSmall from "../../../components/LoadingSpinnerSmall.vue";

export default defineComponent({
  name: "SubscriptionListItem",
  components:{
    LoadingSpinnerSmall,
    BellIcon,
    CheckCircleIcon,
    XCircleIcon,
    CountryIcon,
    PresentationChartLineIcon,
    SparklesIcon,
    ShoppingCartIcon,
    TruckIcon
  },
  props:{
    subscription:{
      type: Object as PropType<EmailSubscription>,
      required: true
    },
    isUpdating:{
      type: Boolean,
      required: false,
      default:false
    }
  },
  emits:[
    'onToggleSubscription'
  ],
  setup(props, context){
    const alertDescriptions = {
      'deals': "Daily alert with the latest deals for the given country.",
      'new-products': "Real time alert when a new product listing is added.",
      'lowest-price': "Real time alert when the lowest recorded price of a product is discovered.",
      'back-in-stock': "Real time alert when a product that was previously out of stock, is now in stock."
    }

    const toggleSubscription = (active: boolean) => {
      props.subscription.isLoading = true;
      context.emit('onToggleSubscription', {
        'subscription': props.subscription,
        'active': active
      });
    }


    return {
      alertDescriptions,
      toggleSubscription
    }
  }
})
</script>