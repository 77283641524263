<template>
    <div class="bg-indigo-700 dark:bg-gray-700 rounded">
        <div class="px-4 sm:px-6 lg:px-4">
            <section aria-labelledby="filter-heading" class="py-2">
                <div class="flex justify-between">
                    <div class="mt-1">
                        <button type="button" class="text-white hover:text-gray-200 dark:text-gray-200 lg:hidden text-sm" @click="handleFilterButtonClick">
                            <div class="inline-flex">
                                <FilterIcon class="w-4 h-4" aria-hidden="true" /><span class="ml-1">Filter</span>
                            </div>
                        </button>
                    </div>
                    <div>
                        <Menu as="div" class="relative z-10w">
                            <div class="">
                                <MenuButton
                                    class="group inline-flex justify-center text-sm font-medium text-white dark:text-gray-200 group-hover:text-gray-800">
                                    Sort
                                    <ChevronDownIcon
                                        class="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-white dark:text-gray-200 group-hover:text-gray-800"
                                        aria-hidden="true"/>
                                </MenuButton>
                            </div>

                            <transition enter-active-class="transition ease-out duration-100"
                                        enter-from-class="transform opacity-0 scale-95"
                                        enter-to-class="transform opacity-100 scale-100"
                                        leave-active-class="transition ease-in duration-75"
                                        leave-from-class="transform opacity-100 scale-100"
                                        leave-to-class="transform opacity-0 scale-95">
                                <MenuItems
                                    class="origin-top-right absolute right-0 dark:bg-gray-700 z-10 mt-2 w-40 rounded-md shadow-2xl bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <div class="py-1">
                                        <MenuItem v-for="option in sortOptions" :key="option">
                                            <a @click="handleSortChange(option.value)"
                                               class="cursor-pointer"
                                               :class="[option.selected ? 'bg-gray-100 dark:bg-gray-900' : '', 'block px-4 py-2 text-sm font-medium text-gray-900 dark:text-gray-200']">
                                                {{ option.name }}
                                            </a>
                                        </MenuItem>
                                    </div>
                                </MenuItems>
                            </transition>
                        </Menu>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import {ref, nextTick, defineComponent} from 'vue'
import {
    Dialog,
    DialogOverlay,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Popover,
    PopoverButton,
    PopoverGroup,
    PopoverPanel,
    TransitionChild,
    TransitionRoot,
} from '@headlessui/vue'
import {XIcon} from '@heroicons/vue/outline'
import {ChevronDownIcon, FilterIcon} from '@heroicons/vue/solid'

export default defineComponent({
    name: "SortOptions",
    components: {
        Dialog,
        DialogOverlay,
        Disclosure,
        DisclosureButton,
        DisclosurePanel,
        FilterIcon,
        Menu,
        MenuButton,
        MenuItem,
        MenuItems,
        Popover,
        PopoverButton,
        PopoverGroup,
        PopoverPanel,
        TransitionChild,
        TransitionRoot,
        ChevronDownIcon,
        XIcon,
    },
    emits: [
        'onSortChange',
        'onFiltersOpened'
    ],
    props: {
        sortOptions: {
            type: Array,
            required: false,
            default: []
        }
    },
    setup(props, context) {
        const open = ref(false)

        const sortOptions = ref(props.sortOptions);

        const handleSortChange = (value) => {
            context.emit('onSortChange', value);
        }

        const handleFilterButtonClick = () => {
            context.emit('onFiltersOpened', true);
        }

        return {
            open,
            handleSortChange,
            handleFilterButtonClick,
            sortOptions
        }
    },
});
</script>