export interface FindAndReplaceStrings {
    [name: string]: string
}
const prettyPrintKey = (slugs: string) => {
    const findAndReplaceFullStrings: FindAndReplaceStrings = {
        "released_at" : "Release Date"
    }

    const findAndReplaceSnippets: FindAndReplaceStrings = {
        "tcg": "TCG"
    }

    if(typeof findAndReplaceFullStrings[slugs] !== "undefined"){
        return findAndReplaceFullStrings[slugs];
    }

    slugs = slugs.replace(/_/g, '-');
    slugs = slugs.replace(/--/g, '-');

    let list: string[] = [];
    slugs.split('-').forEach(function (slug) {
        if(typeof findAndReplaceSnippets[slug] !== "undefined"){
            list.push(findAndReplaceSnippets[slug])
        } else {
            list.push(slug.substr(0, 1).toUpperCase() + slug.substr(1));
        }
    })
    return list.join(' ');
}

export default {
    prettyPrintKey
}