import { ref } from "vue";

const titlePrefix = ref('');

function setTitlePrefix(prefix: string) {
    titlePrefix.value = prefix;
    document.title = prefix ? `${prefix} 🂠 TCG Compare` : "🂠 TCG Compare";
}

export default function() {
    return {
        titlePrefix,
        setTitlePrefix
    };
}
