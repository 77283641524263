<!-- This example requires Tailwind CSS v2.0+ -->
<template>
    <Menu as="div" class="relative inline-block text-left">
        <div>
            <MenuButton
                class="inline-flex justify-center w-full rounded-md border border-gray-300 dark:border-gray-900 shadow-sm px-2 pb-2 pt-1 mt-1 bg-white dark:bg-gray-600 text-sm font-medium text-gray-700 hover:bg-gray-100"
                :title="`Viewing default pricing for ${selectedLanguage}`">
                <CountryIcon size="medium" :code="selectedLanguage" class="-mr-1" />
                <ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
            </MenuButton>
        </div>

        <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
            <MenuItems class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white dark:bg-gray-800 dark:text-gray-200 ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none z-101">
                <div class="py-1">
                    <MenuItem v-slot="{ active }" v-for="language in supportedLanguages">
                        <a @click="setLanguage(language)" :class="[active ? 'bg-gray-100 text-gray-900 dark:text-gray-100' : 'text-gray-700 dark:text-gray-200', 'group flex items-center px-4 py-2 text-sm hover:bg-indigo-900 hover:text-gray-200']">
                            <CountryIcon :code="language" class="mt-0" />
                            {{ language }}
                        </a>
                    </MenuItem>
                </div>
                <div class="py-1 text-xs text-center">
                    More countries coming soon
                </div>
            </MenuItems>
        </transition>
    </Menu>
</template>

<script>
import {computed} from 'vue';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import {
    ArchiveIcon,
    ArrowCircleRightIcon,
    ChevronDownIcon,
    DuplicateIcon,
    HeartIcon,
    PencilAltIcon,
    TrashIcon,
    UserAddIcon,
} from '@heroicons/vue/solid'
import CountryIcon from "../../modules/products/components/CountryIcon.vue";
import useLocalStorageStore from "../../utils/useLocalStorageStore";

export default {
    components: {
        CountryIcon,
        Menu,
        MenuButton,
        MenuItem,
        MenuItems,
        ArchiveIcon,
        ArrowCircleRightIcon,
        ChevronDownIcon,
        DuplicateIcon,
        HeartIcon,
        PencilAltIcon,
        TrashIcon,
        UserAddIcon,
    },
    setup(){
        const localStorageStore = useLocalStorageStore();
        const selectedLanguage = computed( () => localStorageStore.language);
        const supportedLanguages = localStorageStore.getAvailableLanguages;

        const setLanguage = (countryCode) => {
            localStorageStore.setLanguage(countryCode);
        }

        return {
            supportedLanguages,
            setLanguage,
            selectedLanguage
        }
    }
}
</script>