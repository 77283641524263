<template>
    <div class="text-lg pl-2 text-gray-600 dark:text-gray-200">
        Price History
    </div>
    <div class="py-4 pl-2 pr-2 w-full m-auto">
        <div class="w-full py-12 px-4 sm:py-16 sm:px-6 lg:px-8 lg:py-20 bg-indigo-700 dark:bg-gray-700 border-1 rounded">
            <dl class="mt-10 text-center sm:max-w-3xl sm:mx-auto sm:grid sm:grid-cols-3 sm:gap-8">
                <div class="flex flex-col">
                    <dt class="order-2 mt-2 text-lg leading-6 font-medium text-indigo-200">Lowest</dt>
                    <dd class="order-1 text-5xl font-extrabold text-white">{{ stats.lowest }}</dd>
                </div>
                <div class="flex flex-col mt-10 sm:mt-0">
                    <dt class="order-2 mt-2 text-lg leading-6 font-medium text-indigo-200">Average</dt>
                    <dd class="order-1 text-5xl font-extrabold text-white">{{ stats.average }}</dd>
                </div>
                <div class="flex flex-col mt-10 sm:mt-0">
                    <dt class="order-2 mt-2 text-lg leading-6 font-medium text-indigo-200">Highest</dt>
                    <dd class="order-1 text-5xl font-extrabold text-white">{{ stats.highest }}</dd>
                </div>
            </dl>
        </div>
    </div>
    <Responsive class="w-full">
        <template #main="{ width }">
            <div class="bg-white dark:bg-gray-800 dark:text-gray-200">
                <Chart :data="data" :margin="margin" :direction="direction" :size="{width, height:420}" :axis="axis">
                    <template #layers>
                        <Grid strokeDasharray="2,2" />
                        <Line v-for="site in sites" :key="site.id" :data-keys="['Date', site.name]" :lineStyle="{ stroke: site.color }"/>
                        <LabelsLayer v-for="site in sites" :key="site.id" :data-keys="['Date', site.name]" :fill="site.color"/>
                    </template>
                    <template #widgets>
                        <Tooltip
                            borderColor="#48CAE4"
                            format=",.2f"
                            :config="toolTipConfig"
                                    />
                                </template>
                </Chart>
            </div>
        </template>
    </Responsive>
    <div class="text-gray-600 dark:text-gray-200 text-sm text-right pr-4">(showing past month of pricing data)</div>
</template>

<script lang="ts">

import {computed, defineComponent, ref} from "vue";
import { Chart, Grid, Bar, Line, Tooltip, Responsive } from 'vue3-charts';
import LabelsLayer from "./LabelsLayer.vue";

export default defineComponent({
    name: "PriceHistoryChart",
    components: { Chart, Grid, Bar, Line, Tooltip, Responsive, LabelsLayer },
    props:{
        data:{
            type:Array,
            required: true
        },
        sites:{
            type:Array,
            required: true
        },
        stats:{
            type:Object,
            required:true
        }
    },
    setup(props){

        const stats = ref(props.stats);
        const direction = ref('horizontal')
        const margin = ref({
            left: 0,
            top: 20,
            right: 50,
            bottom: 0
        })

        const axis = ref({
            primary: {
                type: 'band'
            },
            secondary: {
                domain: ['dataMin', 'dataMax + 10'],
                type: 'linear',
                ticks: 50
            }
        })

        const toolTipConfig = computed(() => {
            let config = {
                name:{hide:true},
                color:{hide:true}
            };
            props.sites.forEach(s => {
                if(!config[s.name]){
                    config[s.name] = {};
                }
                config[s.name]['color'] = s.color
            })
            return config;
        });

        return {
            axis,
            margin,
            direction,
            toolTipConfig,
            stats
        }
    }
});

</script>
<style>

</style>