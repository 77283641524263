import axios from "axios";
import { ApiAsyncWrapper, ApiResponseWrapper } from "./utils/ApiAsyncWrapper";
import Site from "../types/Site";

function listSites(countryCode: string): Promise<ApiResponseWrapper<Site[]>> {
    return ApiAsyncWrapper(axios.get(`/sites/${countryCode}`));
}

export default {
    listSites
}
