import axios from "axios";
import { ApiAsyncWrapper, ApiResponseWrapper } from "./utils/ApiAsyncWrapper";
import Stats from "../types/Stats";

function getStats(): Promise<ApiResponseWrapper<Stats>> {
    return ApiAsyncWrapper(axios.get(`/stats`));
}

export default {
    getStats
}
