<template>
    <Breadcrumbs
        :game-name="gameName"
        :game-slug="gameSlug"
        :items="breadcrumbList"
    />
    <div class="mt-4 md:flex md:items-center md:justify-between px-2">
        <div class="flex-1 min-w-0">
            <h2 class="text-2xl font-bold leading-7 text-gray-900 dark:text-gray-200 sm:text-3xl sm:truncate">{{ gameName }}</h2>
        </div>
    </div>
</template>
<script lang="ts">
    import useGameStore from "../modules/games/useGameStore";
    import {computed, defineComponent, ref} from "vue";
    import {useRoute} from "vue-router";
    import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/vue/solid";
    import Breadcrumbs from "./Breadcrumbs.vue";

    export default defineComponent({
        name: "Header",
        props:{
            breadcrumbs: {
                type: Array,
                required: false,
                default: []
            }
        },
        components:{
            Breadcrumbs,
            ChevronLeftIcon,
            ChevronRightIcon
        },
        setup(props){

            const route = useRoute();
            const gameStore = useGameStore();
            const breadcrumbList = computed(() => props.breadcrumbs);
            const gameName = ref(route.params.game ? gameStore.getGameNameBySlug(route.params.game) : undefined);
            const gameSlug = ref(route.params.game ?? undefined);
            return {
                breadcrumbList,
                gameName,
                gameSlug
            }
        }
    });
</script>
<style>

</style>