<!-- This example requires Tailwind CSS v2.0+ -->
<template>
    <teleport to="head">
        <link rel="canonical" :href="pageUrl" />
        <meta property="og:title" :content="categoryName" />
        <meta property="og:description" :content="`Compare pricing for products in the ${categoryName} category.`" />
        <meta property="og:image" :content="categoryImagePath" />
    </teleport>
    <LoadingSpinner v-show="isLoading"></LoadingSpinner>
    <Header :breadcrumbs="breadcrumbs"/>

    <section class="flex-1 flex overflow-hidden dark:bg-gray-800 mt-1 px-2">
        <!-- Primary column -->
        <section aria-labelledby="primary-heading" class="min-w-0 flex-1 h-full flex flex-col overflow-y-auto lg:order-last p-4 pl-0 lg:pl-2 pt-0 scrollbar-thin scrollbar-thumb-indigo-700 scrollbar-track-gray-300 overflow-y-scroll scrollbar-thumb-rounded-full scrollbar-track-rounded-full">

            <SubCategoryDetails v-if="isSubCategory" :category="category"/>

            <div class="pt-4">
                <SortOptions
                    v-show="!isLoading"
                    :sort-options="sortOptions"
                    @on-sort-change="handleSortOptionsChange"
                    @on-filters-opened="handleFilterPanelOpened"
                />
                <strong class="text-gray-800 dark:text-gray-200 text-xl">{{ categoryName }}</strong>
<!--                <p v-if="isSubCategory && category.description"-->
<!--                class="text-gray-800 dark:text-gray-200 text-sm whitespace-pre-line">-->
<!--                    {{ category.description }}-->
<!--                </p>-->
                <div class="mt-6 mb-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-5 gap-y-10 gap-x-4">
                  <CategoryProductsWithAdsList
                      :products="products"
                      :gameSlug="gameSlug"
                    />
                </div>
            </div>

            <Pagination
                v-if="products"
                :paging="products"
                @linkClicked="getNewPaginatedData"
            />
            <Footer class=""/>
        </section>

        <!-- Secondary column (hidden on smaller screens) -->
        <aside class="hidden lg:block lg:flex-shrink-0 lg:order-first">
            <div class="h-full relative flex flex-col gap-2 w-64 bg-white dark:bg-gray-800 overflow-y-auto scrollbar-thin scrollbar-thumb-indigo-700 scrollbar-track-gray-300 overflow-y-scroll scrollbar-thumb-rounded-full scrollbar-track-rounded-full" id="filters-panel">

                <Filters
                    v-show="!isLoading"
                    :filters="filters"
                    :sort-options="sortOptions"
                    :filter-panel-open="filterPanelOpen"
                    @on-options-change="handleFilterOptionsChange"
                    @on-filters-opened="handleFilterPanelClosed"
                />
            </div>
        </aside>
    </section>
</template>

<style>
div > div > ins.adsbygoogle[data-ad-status="unfilled"] {
    display: none !important;
}
ins.adsbygoogle {
    margin-bottom:5px;
}
</style>

<script>
import useProductStore from "../../products/useProductStore";
import {useRoute, useRouter} from "vue-router";
import {computed, nextTick, onBeforeMount, ref, watch} from "vue";
import useCategoryStore from "../useCategoryStore";
import Header from "../../../components/Header.vue";
import useTitleManager from "../../../utils/useTitleManager";
import LoadingSpinner from "../../../components/LoadingSpinner.vue";
import Pagination from "../../../components/Pagination.vue";
import Filters from "../components/Filters.vue";
import Footer from "../../../layouts/components/Footer.vue";
import SortOptions from "../components/SortOptions.vue";
import SubCategoryPanel from "../../products/components/SubCategoryPanel.vue";
import CategoryProductsWithAdsList from "../components/CategoryProductsWithAdsList.vue";
import useLocalStorageStore from "../../../utils/useLocalStorageStore";
import useSiteStore from "../../sites/useSiteStore";
import SubCategoryDetails from "../components/SubCategoryDetails.vue";

export default {
    components: {
        SubCategoryDetails,
        CategoryProductsWithAdsList,
        SubCategoryPanel,
        SortOptions,
        Filters,
        Footer,
        LoadingSpinner,
        Header,
        Pagination,
    },
    setup() {
        const route = useRoute();
        const router = useRouter();
        const gameSlug = route.params.game;
        const categorySlug = route.params.categorySlug;
        const productStore = useProductStore();
        const categoryStore = useCategoryStore();
        const siteStore = useSiteStore();
        const localStorageStore = useLocalStorageStore();
        const products = computed(() => productStore.products);
        const category = ref();
        const categoryName = ref();
        const categoryImagePath = ref();
        const { setTitlePrefix } = useTitleManager();
        const isLoading = ref(false);
        const active = ref(false);
        const filterPanelOpen = ref(false);
        const isSubCategory = ref(false);

        const selectedFilters = ref(route.query.filters ?? undefined);
        const selectedSort = ref(route.query.sort ?? undefined);
        const selectedPage = ref(route.query.page ?? undefined);

        const sortOptions = ref([
            {name: 'Newest First', value: "newest", selected: false},
            {name: 'Oldest First', value: "oldest", selected: false},
            {name: 'Alphabetically', value: "az", selected: false},
        ]);
        const filters = ref([
            {
                id: 's',
                name: 'Availability',
                options: [
                    {value: "in_stock", label: 'In Stock', checked: true},
                    {value: "out_of_stock", label: 'Out of Stock', checked: false}
                ],
            },
            {
                id: 'sc',
                name: 'Set',
                options: []
            },
            {
                id: 'si',
                name: 'Site',
                options: []
            }
        ]);

        const breadcrumbs = ref([]);

        const setup = async () => {
            isLoading.value = true;
            isSubCategory.value = false;
            await productStore.clearProducts();
            let options = {};

            if(selectedPage.value){
                options.page = selectedPage.value;
            }

            await categoryStore.listCategories(gameSlug);
            await categoryStore.listSubCategories(gameSlug);
            await siteStore.listSites(localStorageStore.language);
            category.value = categoryStore.getCategoryBySlug(route.params.categorySlug);
            if(!category.value?.id){
                category.value = categoryStore.getSubCategoryBySlug(route.params.categorySlug);
                isSubCategory.value = true;
            }
            categoryName.value = category.value?.name;
            categoryImagePath.value = category.value?.image_path;

            breadcrumbs.value = [
                {
                    label: category.value?.name,
                    path: route.params.categorySlug
                }
            ]

            if(isSubCategory.value === false){
                const idx = filters.value.findIndex(x => x.id === "sc");
                filters.value[idx].options = categoryStore.subCategories.map(x => {
                    return {
                        value: x.slug,
                        label: x.name
                    }
                });
            }

            const idx = filters.value.findIndex(x => x.id === "si");
            filters.value[idx].options = siteStore.sites.map(x => {
                return {
                    value: x.slug,
                    label: x.name,
                    image_path: x.image_path
                }
            });

            if(selectedFilters.value){
                selectedFilters.value.forEach(x => {
                    if(x.values.length > 0){
                        options[x.id] = x.values
                    }
                });
                selectedFilters.value.forEach(function (e){
                    filters.value = filters.value.map((x) => {
                        if(x.id === e.id){
                            x.options.map((y) => {
                                e.values.split(',').map(v => {
                                    if (y.value === v) {
                                        y.checked = true;
                                    }
                                });
                            })
                        }
                        return x;
                    })
                });
            }
            if(selectedSort.value){
                options.sort = selectedSort.value;
                sortOptions.value = sortOptions.value.map((x) => {
                    x.selected = x.value === selectedSort.value;
                    return x;
                })
            }

            options.country = localStorageStore.language;

            const params = new URLSearchParams(options);
            params.delete('country');
            let url = `/${gameSlug}/${categorySlug}`;
            if(params.toString().length>0){
                url = url+`?${params.toString()}`;
            }

            await router.push(url);
            if(isSubCategory.value === true){
                await productStore.listProductsInSubCategory(gameSlug, categorySlug, options);
            }
            else{
                await productStore.listProductsInCategory(gameSlug, categorySlug, options);
            }

            isLoading.value = false;
            await nextTick(() => {
                const element = document.getElementById("main");
                element.scrollTop = 0;
            });
        }

        onBeforeMount(async () => {
            await setup();
            setTitlePrefix(categoryName.value);
        });

        watch(() => localStorageStore.language, async () => {
            await setup();
        });



        const urlParams = (url) => {
            return new Proxy(new URLSearchParams(new URL(url).search), {
                get: (searchParams, prop) => searchParams.get(prop)
        })};

        async function getNewPaginatedData(link){
            await productStore.clearProducts();
            const params = urlParams(link.url);
            selectedPage.value = params.page !== "1" ? params.page : undefined;
            await setup();
        }

        const handleFilterOptionsChange = async(values) => {
            selectedFilters.value = values.map((x) => {
                return {
                    'id': x.id,
                    'values': x.options.filter((y) => y.checked).map((z) => z.value).join(",")
                }
            });
            selectedPage.value = undefined;
            await setup();
        }

        const handleSortOptionsChange = async(value) => {
            selectedSort.value = value;
            selectedPage.value = undefined;
            await setup();
        }

        const handleFilterPanelOpened = () => {
            filterPanelOpen.value = true;
        }

        const handleFilterPanelClosed = () => {
            filterPanelOpen.value = false;
        }

        return {
            isLoading,
            isSubCategory,
            breadcrumbs,
            gameSlug,
            categorySlug,
            products,
            category,
            categoryName,
            categoryImagePath,
            getNewPaginatedData,
            handleFilterOptionsChange,
            handleSortOptionsChange,
            selectedFilters,
            selectedSort,
            filters,
            sortOptions,
            active,
            handleFilterPanelOpened,
            handleFilterPanelClosed,
            filterPanelOpen,
            pageUrl: window.location.href
        }
    }
}
</script>