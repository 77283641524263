import {ApiAsyncWrapper, ApiResponseWrapper} from "./utils/ApiAsyncWrapper";
import axios from "axios";

class AuthApiClient {

    // This is needed before attempting to make a call to login
    async getCSRFCookie(): Promise<any> {
        return ApiAsyncWrapper(axios.get(`/sanctum/csrf-cookie`));
    }

    async verifyEmail(token: string): Promise<ApiResponseWrapper<unknown>> {
        return ApiAsyncWrapper(axios.post(`/alerts/email-confirmation/${token}`));
    }
}

export default new AuthApiClient();
