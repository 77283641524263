import axios from "axios";
import { ApiAsyncWrapper, ApiResponseWrapper } from "./utils/ApiAsyncWrapper";
import EmailSubscription from "../types/EmailSubscription";
import EmailSubscriptionType from "../types/EmailSubscriptionType";
import Product from "../types/Product";
import Country from "../types/Country";
import PaginatedResponse from "../types/PaginatedResponse";
import EmailSubscriptionRequest from "../types/EmailSubscriptionRequest";

function listSubscriptions(token: string, paginationUrl?: string): Promise<ApiResponseWrapper<PaginatedResponse<EmailSubscription[]>>> {
    return ApiAsyncWrapper(axios.get(paginationUrl ? paginationUrl : `/alerts/list/${token}`));
}

function toggleAlert(id: number, type: EmailSubscriptionType, country: Country, active: boolean, product?: Product | null): Promise<ApiResponseWrapper<EmailSubscription>>{
    return ApiAsyncWrapper(axios.post("/alerts/toggle", {
        id: id,
        type: type.slug,
        country: country.code,
        active: active,
        product: product?.slug
    }));
}

function unsubscribeAllAlerts(token: string): Promise<ApiResponseWrapper<unknown>> {
    return ApiAsyncWrapper(axios.post(`/alerts/unsubscribe`, {
        token: token
    }));
}

function requestLogin(email: string): Promise<ApiResponseWrapper<unknown>> {
    return ApiAsyncWrapper(axios.post(`/alerts/request`, {
        email: email
    }));
}

function subscribe(subscription: EmailSubscriptionRequest): Promise<ApiResponseWrapper<EmailSubscription>> {
    return ApiAsyncWrapper(axios.post("/alerts/subscribe", {
        type: subscription.type,
        name: subscription.name,
        email: subscription.email,
        country: subscription.country.code,
        product: subscription.product?.slug
    }))
}

export default {
    listSubscriptions,
    toggleAlert,
    unsubscribeAllAlerts,
    subscribe,
    requestLogin
}
