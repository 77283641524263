<template>
    <teleport to="head">
        <link rel="canonical" :href="pageUrl" />
        <meta property="robots" content="noindex" />
    </teleport>
    <div class="flex flex-col justify-center spacing h-full flex overflow-hidden dark:bg-gray-800 dark:text-gray-200 p-4">
        <div class="text-center text-2xl mt-2">You're Offline.</div>
        <div class="text-center text-lg mt-2">This application only works with an active internet connection...</div>
        <div class="text-center text-sm mt-5">Please make sure you are online, then <router-link to="/" class="text-indigo-300 cursor-pointer">click here</router-link> to go back to the home page.</div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import LoadingSpinner from "./components/LoadingSpinner.vue";

export default defineComponent({
    name: 'App',
    components: {
        LoadingSpinner,
    },
    setup(){
        return {
            pageUrl: window.location.href
        }
    }
})
</script>

<style>
</style>

